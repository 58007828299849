import React from "react"
import Hero from "./hero"
import Section from "./section";

const PageHeader = ({ title, intro, center, className, tone = '', cta, bgImage, mobileBgImage, bgColor, height, items, activeSlug, style }) => {
  const titleInner = title?.replace(/_/g, ' ');
  const heroData = {
    heading: titleInner,
    headingElement: 'h1',
    intro: intro,
    bgImage: bgImage,
    mobileBgImage: mobileBgImage,
    ctaList: cta,
    style: {
      container: `${style?.container} w-full`,
      column: `${style?.column} md:flex-col sm:!justify-center lg:items-center top-0 !justify-end items-center gap-3 md:pt-28 w-full`,
      intro: style?.intro,
      heading: `${style?.heading} text-3xl md:text-4xl font-owners-text`
    },
		isCentered: true,
    items,
    activeSlug: activeSlug
  };

  return (
    <Section settings={{isContainer: false, classname:`!mb-0 ${bgColor}` }}>
      <header className={`${center && `text-center`} md:h-[359px] ${height ? height : 'h-[250px]'} w-full mx-auto antialiased ${tone} ${className}`} tabIndex={0}>
        <Hero {...{...heroData, isPageHeader: true }} />
      </header>
    </Section>
  )
}

export default PageHeader

PageHeader.defaultProps = {
  className: '',
  center: false,
}
